import React from "react";

const Button = (props: any) => {
  const { onClick, title, icons } = props;

  return (
    <>
      <button
        onClick={onClick}
        className="bg-[#4338CA] flex items-center justify-center  rounded-lg text-center text-white p-2 "
      >
        <img src={icons} alt="" />
        <div className="pl-1">{title}</div>
      </button>
    </>
  );
};

export default Button;
