import RolesList from "../../components/Roles/RolesList";
import APIS from "../../constants/EndPoint";
import useDeleteHook from "../../customHooks/useDeleteHook";
import useGetHook from "../../customHooks/useGetHook";
import usePaginationState from "../../customHooks/usePaginationState";

const Roles = () => {
  const { limit, setLimit, offset, setOffset } = usePaginationState();

  const { isLoading, isError, data, isSuccess } = useGetHook({
    queryKey: [`role`],
    url: `${APIS.ROLES_LIST}`,
    params: null,
  });

  const { mutateAsync: deleteMutateAsync } = useDeleteHook({
    queryKey: ["roles"],
  });

  const handleDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.ROLES_LIST}${pk}/`;
    try {
      const res = await deleteMutateAsync({ url });
      console.log(res);
      setOpenPopup(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <RolesList
      data={data ?? []}
      loading={isLoading}
      count={data?.data?.count}
      limit={limit}
      setLimit={setLimit}
      offset={offset}
      setOffset={setOffset}
      hasNextPage={data?.data?.next}
      hasPreviousPage={data?.data?.previous}
      handleDelete={handleDelete}
    />
  );
};

export default Roles;
