import React from 'react'

const ToggleButton = (props: any) => {
  const { getValue, row, handleChange } = props
  return (
    <>
      <div className="flex w-full">
        <label className="flex items-center cursor-pointer">
          <div className="relative">
            <input type="checkbox" checked={getValue()} className="sr-only"
              onChange={(e) => handleChange(e, row.original.id)}
            />
            <div className="block bg-gray-200 w-[40px] h-6 rounded-full"></div>
            <div className="dot absolute left-1 top-1 bg-[#999999] w-4 h-4 rounded-full transition"></div>
          </div>
        </label>
      </div>
    </>
  )
}

export default ToggleButton