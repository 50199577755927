import * as yup from "yup";
import parsePhoneNumberFromString from "libphonenumber-js";

let LoginFormValidationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

let ServicesFormValidationSchema = yup.object().shape({
  domain: yup.string().required("Domain name is required"),
  logo: yup
    .mixed()
    .test("fileList", "Logo is required", (value: any) =>
      value.length === 0 ? false : true
    ),
});

let RolesFormValidationScheme = yup.object().shape({
  roleName: yup.string().required("Role is required"),
});

let clientFormValidationSchema = yup.object().shape({
  domain: yup.string().required("Domain name is required"),
  client_name: yup.string().required("Client name is required"),
  business_name: yup.string().required("Business name is required"),
  email: yup.string().required("Email name is required"),
});

let profileFormValidationSchema = yup.object().shape({
  full_name: yup.string().trim().required("FullName is required"),
  contact_number: yup
    .string()
    .test("is-valid-phone", "Invalid contact number", (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value);
      return phoneNumber && phoneNumber.isValid();
    }),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email is not valid"
    ),
});

let userFormValidationSchema = yup.object().shape({
  full_name: yup.string().required("Full Name is required"),
  email: yup.string().required("Email name is required"),
  contact_number: yup
    .string()
    .test("is-valid-phone", "Invalid contact number", (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value);
      return phoneNumber && phoneNumber.isValid();
    }),
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Your password is too short"),
  confirm_password: yup
    .string()
    .required("Please retype your password")
    .oneOf([yup.ref("password")], "Your passwords do not match"),
});

let ChangePasswordValidationSchema = yup.object().shape({
  old_password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Your password is too short"),
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Your password is too short"),
  confirm_password: yup
    .string()
    .required("Please retype your password")
    .oneOf([yup.ref("password")], "Your passwords do not match"),
});
export {
  LoginFormValidationSchema,
  ServicesFormValidationSchema,
  clientFormValidationSchema,
  RolesFormValidationScheme,
  profileFormValidationSchema,
  userFormValidationSchema,
  ChangePasswordValidationSchema,
};
