import React, { Children, useEffect, useState } from "react";

import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { classNames } from "../../../utils/classNames";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import PageTitle from "../PageTitle";
import useGetHook from "../../../customHooks/useGetHook";
import DataLoadSpinner from "../Spinner";

function DataTable(props: any) {
  const { url, queryKey, columns, tableName, icons, onClick } = props;
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const { data: serverData, isLoading } = useGetHook({
    queryKey: [queryKey, `${queryKey}-${limit}-${page}`],
    url: url,
    params: {
      limit: limit,
      offset: limit * page,
    },
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    if (serverData?.results.length) {
      setData(serverData?.results ?? []);
    }
  }, [serverData]);
  const navigate = useNavigate();
  // Create the table and pass your options
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    table.setPageSize(limit);
  }, [limit]);

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
  };

  const handleLastpage = () => {
    setPage(Math.floor(serverData?.count / limit));
  };

  const handleFirstPage = () => {
    setPage(0);
  };

  return (
    <>
      {isLoading ? (
        <DataLoadSpinner />
      ) : (
        <div className="w-full">
          <div className="w-full flex justify-between items-center">
            <PageTitle pageTitle={tableName} />
            <Button title="Add New" icons={icons} onClick={onClick} />
          </div>
          <div className="overflow-x-auto">
            <table className="w-full border-2">
              <thead className="bg-[#4338CA] text-white">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="">
                    {headerGroup.headers.map((header, index) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className={classNames(
                          index === 0
                            ? "rounded-tl-lg"
                            : headerGroup.headers.length - 1 === index
                            ? "rounded-tr-lg text-left w-[8rem]"
                            : "",
                          "py-[10px] text-left px-5"
                        )}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="even:bg-gray-50 odd:bg-white "
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="py-4 text-left px-5">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.footer,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            </table>
          </div>
          <div className="h-2" />
          <div className="flex py-2 w-full justify-between gap-4 overflow-x-auto">
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                setLimit(Number(e.target.value));
              }}
              className="outline-none border-none p-2"
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <div>
              <button
                className="border px-3 py-1 bg-white"
                onClick={() => handlePreviousPage()}
                disabled={!serverData?.previous}
              >
                {"<"}
              </button>
              <button
                className="border px-3 py-1 bg-white"
                onClick={() => handleNextPage()}
                disabled={!serverData?.next}
              >
                {">"}
              </button>
            </div>
          </div>
          <div className="h-4" />
        </div>
      )}
    </>
  );
}

export default DataTable;
