import { useState } from "react";
import DataTable from "../Reusable/DataTable";
import NACell from "../Reusable/Table/NACell";
import TableActionButton from "../Reusable/TableActionButton";
import APIS from "../../constants/EndPoint";
import TickIcon from "../../assets/icons/tick.svg";
import CrossIcon from "../../assets/icons/cross.svg";
import AddIcons from "../../assets/icons/addIcon.svg";
import Form from "./Form";
import Popup from "../Reusable/Popup";
import ToggleButton from "../Reusable/ToggleButton";

const UserLists = (props: any) => {
  const { handleAddUser, handleIsActive, handleIsSuperUser, handleIsStaff } =
    props;
  const [isOpen, setIsOpen] = useState(false);
  const defaultColumns: any = [
    {
      header: "FullName",
      accessorKey: "full_name",
      cell: NACell,
    },
    {
      header: "Email",
      accessorKey: "email",
      cell: NACell,
    },
    {
      header: "Username",
      accessorKey: "username",
      cell: NACell,
    },
    {
      header: "Mobile Number",
      accessorKey: "mobile_number",
      cell: NACell,
    },
    {
      header: "Active",
      accessorKey: "is_active",
      cell: ({ row, getValue }) => (
        <ToggleButton
          row={row}
          getValue={getValue}
          handleChange={handleIsActive}
        />
      ),
    },
    {
      header: "Staff",
      accessorKey: "is_staff",
      cell: ({ row, getValue }) => (
        <ToggleButton
          row={row}
          getValue={getValue}
          handleChange={handleIsStaff}
        />
      ),
    },
    {
      header: "Superuser",
      accessorKey: "is_superuser",
      cell: ({ row, getValue }) => (
        <ToggleButton
          row={row}
          getValue={getValue}
          handleChange={handleIsSuperUser}
        />
      ),
    },
    {
      header: "Action",
      accessorKey: "id",
      cell: ({ row, getValue }) => (
        <div className="pl-3">
          <TableActionButton viewAction={`/view-user/${getValue()}`} />
        </div>
      ),
    },
  ];
  return (
    <>
      <DataTable
        columns={defaultColumns}
        url={APIS.USER_LIST}
        queryKey={"users"}
        tableName="Users List"
        icons={AddIcons}
        addNewPagePath={""}
        onClick={() => setIsOpen(true)}
      />
      <Popup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        children={
          <Form
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onSubmit={handleAddUser}
          />
        }
        title="User Form"
      />
    </>
  );
};

export default UserLists;
