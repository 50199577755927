import { useState } from "react"

const usePaginationState = () => {
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)
  
  
    return { limit, setLimit, offset, setOffset }
  }
  
  
  
  export default usePaginationState