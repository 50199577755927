import loadable from "@loadable/component";
import React from "react";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../constants/EndPoint";
import { useParams } from "react-router";
import usePatchHook from "../../customHooks/usePatchHook";
import usePostHook from "../../customHooks/usePostHook";
const ViewUserData = loadable(() => import("../../components/User/ViewUser"));

const ViewUser = () => {
  const { id } = useParams();

  // Get the user
  const { isLoading: userLoading, data: userData } = useGetHook({
    queryKey: [`userdata-${id}`],
    url: `${APIS.USER_LIST}${id}/`,
    params: null,
  });

  //update profile
  const { mutateAsync, data } = usePatchHook({
    queryKey: [`userdata-${id}`],
    navigateURL: "",
  });

  const handleUpdate = async (data: any) => {
    const { dob_english, ...rest } = data;
    const formData = dob_english ? { ...rest, dob_english } : rest;
    const url = `${APIS.PROFILE}${userData?.profile?.id}/`;
    try {
      await mutateAsync({ url, formData });
    } catch (err) {
      console.log(err);
    }
  };

  //Update Password
  const { mutateAsync: changePassword } = usePostHook({
    queryKey: ["profile"],
    navigateURL: null,
  });

  const handleChangePassword = async (data: any) => {
    try {
      await changePassword({
        url: "/user/change-password/",
        formData: data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <ViewUserData
        userData={userData}
        handleUpdate={handleUpdate}
        userLoading={userLoading}
        handleChangePassword={handleChangePassword}
      />
    </>
  );
};

export default ViewUser;
