


import "./index.css";

import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import MainLayout from "./layout/MainLayout";

import 'react-toastify/dist/ReactToastify.css';
import UserMgmt from "./pages/User/User";
import UserAdd from "./pages/User/AddUser";

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Roles from "./pages/Roles/Roles";



import DashboardLayout from "./layout/DashboardLayout";
import ViewUser from "./pages/User/ViewUser";
import UpdateRole from "./pages/Roles/UpdateRole";
import AddRoles from "./pages/Roles/AddRoles";


const queryClient = new QueryClient()

const router = createBrowserRouter(
  [
    {
      element: <DashboardLayout />,
      children: [
        {
          path: "/",
          element: <UserMgmt />,
        },
        {
          path: "/view-user/:id",
          element: <ViewUser />,
        },
        {
          path: "/add-user",
          element: <UserAdd />,
        },
        {
          path: "/roles",
          element: <Roles />,
        },
        {
          path: "/update-role/:id",
          element: <UpdateRole />,
        },
        {
          path: "/add-role",
          element: <AddRoles />,
        },
      ]
    }
  ],
  { basename: "/user-management" }
);


export default function Root() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ToastContainer autoClose={1000} />
      </QueryClientProvider>
    </>
  );
}
