import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { putApiData } from "../helpers/axiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const usePutHook = ({ queryKey, navigateURL }) => {
  //getting cached data from queryClient
  const queryClient = useQueryClient();
  const navigate = useNavigate()

  const [updateSuccessSnackBar, setUpdateSuccessSnackBar] = useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(false);
  const {
    isLoading: isUpdateLoading,
    mutateAsync,
    isSuccess: isUpdateSuccess,
    data: updateData,
    isError: isUpdateError,
  } = useMutation(putApiData, {
    onSuccess: (data) => {
      if (data.status === 201 || data.status === 200) {
        toast.success("Success");
        if (navigateURL) {
          navigate(navigateURL)
        }
      }
    },
    onError: (error) => {
      setErrorSnackBar(true);
    },
    onSettled: () => {
      //  When the mutation succeeds, invalidate or re-render queries with provided queryKey
      //  (or re-render particular component)
      queryClient.invalidateQueries(queryKey);
    },
  });

  return {
    isUpdateLoading,
    mutateAsync,
    isUpdateSuccess,
    updateData,
    isUpdateError,
    updateSuccessSnackBar,
    setUpdateSuccessSnackBar,
    setErrorSnackBar,
    errorSnackBar,
    navigateURL,
  };
};

export default usePutHook;
