const APIS = {
  USER_LIST: 'user/',
  ROLES_LIST: 'role/',

  CREATE_USER: `user/create/`,

  PERMISSIONS: 'permissions/',
  CHANGEPASSWORD: '/user/change-password/',
  PROFILE: 'profile/'

}

export default APIS