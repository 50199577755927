import { useQuery } from "@tanstack/react-query";
import { getApiData } from "../helpers/axiosInstance";

const useGetHook = ({ queryKey, url, params, isEnabled = true }) => {
  const { isLoading, isError, data, isSuccess } = useQuery(queryKey, async () => {
    const response = await getApiData(url, params);
    return response.data;
  },
    {
      refetchOnWindowFocus: false,
      enabled: isEnabled,
      keepPreviousData: true,

    });

  return { isLoading, isError, data, isSuccess };
};

export default useGetHook;