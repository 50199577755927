import DataTable from "../Reusable/DataTable";
import NACell from "../Reusable/Table/NACell";
import TableActionButton from "../Reusable/TableActionButton";
import APIS from "../../constants/EndPoint";
import AddIcons from "../../assets/icons/addIcon.svg";
import Popup from "../Reusable/Popup";
import { useState } from "react";
import ConfirmDialog from "../Reusable/ConFirmDialog";
import { useNavigate } from "react-router";

const RolesList = (props: any) => {
  const { handleDelete } = props
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedObject, setSelectedObject] = useState(null)
  const defaultColumns: any = [
    {
      header: 'Title',
      accessorKey: 'group_name',
      cell: NACell
    },
    {
      header: 'Action',
      accessorKey: 'pk',
      cell: ({ row, getValue }) => <>
        <TableActionButton
          value={getValue}
          editAction={`/update-role/${getValue()}`}
          setOpenPopup={setIsOpen}
          setSelectedObject={setSelectedObject}

        />
      </>
    },
  ]
  return (
    <>
      <DataTable
        columns={defaultColumns}
        url={APIS.ROLES_LIST}
        queryKey={"roles"}
        tableName="RolesList"
        icons={AddIcons}
        onClick={() => navigate("/add-role")}

      />
      <Popup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        children={<ConfirmDialog
          setIsOpen={setIsOpen}
          handleDelete={() => handleDelete(setIsOpen, selectedObject)}
        />}
        title="Delete" />
    </>
  );
};

export default RolesList;
