import { useNavigate } from "react-router-dom";
import ViewIcon from "../../../assets/icons/viewAction.svg";
import EditIcon from "../../../assets/icons/EditIcon.png";
import DeleteIcon from "../../../assets/icons/deleteIcon.svg";

const TableActionButton = (props: any) => {
  const { viewAction, editAction, setSelectedObject, setOpenPopup, value } =
    props;
  const navigate = useNavigate();

  const handleDetailViewRoute = () => {
    navigate(viewAction);
  };
  const handleEditViewRoute = () => {
    navigate(editAction);
  };
  const handleDelete = () => {
    console.log(value, "value");
    setSelectedObject(value);
    setOpenPopup(true);
  };
  return (
    <>
      <div className="w-full text-center flex gap-4 ">
        {viewAction && (
          <button onClick={handleDetailViewRoute}>
            <img src={ViewIcon} alt="" className="text-[#4338CA] w-5 bg-" />
          </button>
        )}
        {editAction && (
          <button onClick={handleEditViewRoute}>
            <img src={EditIcon} alt="" className="text-indigo-300 w-5" />
          </button>
        )}

        {setOpenPopup && (
          <button onClick={handleDelete}>
            <img src={DeleteIcon} alt="" className="text-indigo-300 w-5" />
          </button>
        )}
      </div>
    </>
  );
};

export default TableActionButton;
