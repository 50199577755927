import React, { useState } from 'react'
import loadable from "@loadable/component";
import usePostHook from '../../customHooks/usePostHook';
import APIS from '../../constants/EndPoint';
import usePaginationState from '../../customHooks/usePaginationState';
import useGetHook from '../../customHooks/useGetHook';
const AddRoleComponent = loadable(() => import("../../components/Roles/AddRoles"));
const AddRoles = () => {
  const { limit, setLimit, offset, setOffset } = usePaginationState();

  const [array, setArray] = useState([])


  const { isLoading, isError, data, isSuccess } = useGetHook({
    queryKey: [`per,issions-${limit}-${offset}`],
    url: `${APIS.PERMISSIONS}?limit=${limit}&offset=${offset}`,
    params: null
  })

  const { isPostLoading, mutateAsync, postData } = usePostHook({ queryKey: ["roles"], navigateURL: "/roles" })
  const handleCreateRoles = async (values: any) => {
    console.log(values, "test");
    const url = APIS.ROLES_LIST
    const formData = {
      role: values.roleName,
      custom_permissions: array ?? []
    }
    try {
      const res = await mutateAsync({ url, formData })
      console.log(res);
    } catch (err) {
      console.log(err);
    }

  };


  return (
    <AddRoleComponent
      handleCreateRoles={handleCreateRoles}
      data={data ?? {}}
      setArray={setArray}
    />
  )
}

export default AddRoles