import APIS from "../../constants/EndPoint";
import usePostHook from "../../customHooks/usePostHook"
import AddUserList from "../../components/User/Form";
import { useState } from "react";


const AddUser = (props: any) => {
    const { onSubmit } = props
const[isOpen,setIsOpen]=useState(false)
    // const { isPostLoading, mutate } = usePostHook({ queryKey: "users", navigateURL: "/" });



    const handleAddUser = async (values) => {
        const url = APIS.USER_LIST;
        console.log(values, "values");

        // const formData = values;
        // try {
        //   await mutate({ url, formData })
        // } catch (e) {
        //   console.log(e)
        // }
    }

    return (
        <div><AddUserList
            onSubmit={onSubmit} isOpen={isOpen} setIsOpen={setIsOpen} /> </div>
    )
}

export default AddUser