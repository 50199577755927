import React from "react";
import CloseIcon from "../../assets/icons/close.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { userFormValidationSchema } from "../../utils/validationSchema";
import Button from "../Reusable/Button";
import TextField from "../Reusable/TextField";
import InputField from "../Reusable/Forms/InputField";
import PasswordField from "../Reusable/Forms/PasswordField";

type Props = {
  onSubmit: any;
  setIsOpen: any;
  isOpen: any;
};

const Form = (props: Props) => {
  const { isOpen, onSubmit, setIsOpen } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userFormValidationSchema),
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 relative md:grid-cols-2 gap-7 mb-4">
        <div className="absolute top-[-30px] right-0 cursor-pointer">
          <img
            src={CloseIcon}
            alt=""
            onClick={() => setIsOpen(false)}
            style={{ color: "black" }}
          />
        </div>
        <InputField
          name="full_name"
          label="Full Name"
          register={register}
          type={"text"}
          errors={errors}
        />
        <InputField
          name="email"
          label="Email"
          register={register}
          type={"email"}
          disabled={false}
          errors={errors}
        />
        <InputField
          name="contact_number"
          label="Mobile Number"
          register={register}
          disabled={false}
          type={"tel"}
          errors={errors}
        />
        <PasswordField
          name="password"
          label="Password"
          register={register}
          disabled={false}
          type={"text"}
          errors={errors}
        />
        <PasswordField
          name="confirm_password"
          label="Confirm Password"
          register={register}
          disabled={false}
          type={"text"}
          errors={errors}
        />
      </div>
      <Button title={"Add User"} />
    </form>
  );
};

export default Form;
