import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { postApiData } from "../helpers/axiosInstance";
// import { postApiData } from "../helpers/axiosInstance";

const usePostHook = ({ queryKey, navigateURL }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    isLoading: isPostLoading,
    mutateAsync,
    isSuccess: isPostSuccess,
    data: postData,
    isError: isPostError,
  } = useMutation(postApiData, {
    onSuccess: (data) => {
      if (data.status === 201 || data.status === 200) {
        toast.success("Success");
        if (navigateURL) {
          navigate(navigateURL);
        }
      } else {
        console.log(data, "data");
        if (data?.data?.non_field_errors) {
          toast.error(data?.data?.non_field_errors[0]);
        } else {
          toast.error(data?.data?.message ?? "Something went wrong");
        }
      }
    },
    onError: (error) => {
      toast.error("Error");
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
  return {
    isPostLoading,
    mutateAsync,
    isPostSuccess,
    postData,
    isPostError,
  };
};

export default usePostHook;
// import {useMutation, useQueryClient} from "@tanstack/react-query";
// import {toast} from "react-toastify";
// import {apiInstance} from "../helpers/axiosInstance";

// const usePostHook = ({setOpenAddPopup, queryKey, url}: any) => {
//   const queryClient: any = useQueryClient();
//   const {
//     isLoading: isPostLoading,
//     mutate,
//     isSuccess: isPostSuccess,
//     data: postData,
//     isError: isPostError,
//   } = useMutation({
//       mutationFn: data => {
//         // @ts-ignore
//         return apiInstance.post(url, data?.formData).then((res) => {
//           return res
//         }).catch(({response}) => {
//           // @ts-ignore
//           for (const key: any in response?.data) {
//             if (response?.data.hasOwnProperty(key)) {
//               // @ts-ignore
//               data?.setError(key, {message: response?.data[key], type: "required"});
//             }
//           }
//           if (response.status === 500) {
//             toast.error("Some things went wrong to server");
//           } else if (response.status === 400) {
//             toast.error("Invalid Form");
//             // @ts-ignore
//             data?.reset()
//           } else {
//             toast.error("Some things went wrong");
//           }
//         })
//       },
//       onSuccess: (data: any) => {
//         if (data?.status === 201) {
//           setOpenAddPopup(false)
//           toast.success("Item created successfully")
//         }
//         return data
//       },
//       onError: (error: any) => {
//         console.log(error, "error")
//       },
//       onSettled: () => {
//         queryClient.invalidateQueries(queryKey);
//       },
//     }
//   );
//   return {
//     isPostLoading,
//     mutate,
//     isPostSuccess,
//     postData,
//     isPostError,
//   };

// };

// export default usePostHook;
