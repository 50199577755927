import React, { useEffect, useState } from 'react'
import loadable from "@loadable/component";
import APIS from '../../constants/EndPoint';
import useGetHook from '../../customHooks/useGetHook';
import usePatchHook from '../../customHooks/usePatchHook';
import { useParams } from 'react-router';
import usePutHook from '../../customHooks/usePutHook';
import usePaginationState from '../../customHooks/usePaginationState';

const UpdateRoleComponent = loadable(() => import("../../components/Roles/UpdateRole"));

const UpdateRole = () => {
  const { id } = useParams()
  const { mutateAsync } = usePutHook({ queryKey: ["roles", `roles-${id}`], navigateURL: "/roles" })

  const { data: roles, isLoading: rolesLoading } = useGetHook({
    queryKey: [`roles-${id}}`],
    url: `${APIS.ROLES_LIST}${id}/`,
    params: null,
  });
  const handleUpdate = async (values: any) => {
    const url = `${APIS.ROLES_LIST}${id}/`
    const formData = {
      role: values.roleName
    }
    try {
      await mutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }

  const { limit, setLimit, offset, setOffset } = usePaginationState();

  const { isLoading, isError, data, isSuccess } = useGetHook({
    queryKey: [`per,issions-${limit}-${offset}`],
    url: `${APIS.PERMISSIONS}?limit=${limit}&offset=${offset}`,
    params: null
  })

  return (
    <>
      {roles ?
        < UpdateRoleComponent
          handleUpdate={handleUpdate}
          roles={roles}
          data={data ?? {}}
        /> : "Loading"
      }
    </>
  )
}
export default UpdateRole