import UserList from "../../components/User/UserList";
import APIS from "../../constants/EndPoint";
import usePostHook from "../../customHooks/usePostHook";
import usePatchHook from "../../customHooks/usePatchHook";

const User = () => {
  const { mutateAsync } = usePostHook({
    queryKey: ["users"],
    navigateURL: null,
  });

  const handleAddUser = async (values) => {
    console.log(values, "value");
    const url = APIS.USER_LIST;
    const formData = values;
    try {
      await mutateAsync({ url, formData });
    } catch (e) {
      console.log(e);
    }
  };

  const { mutateAsync: patchActiveMutateAsync } = usePatchHook({
    queryKey: ["users"],
    navigateURL: null,
  });

  const handleIsActive = async (e: any, id: any) => {
    const payload = {
      is_active: e.target.checked,
    };
    const url = `${APIS.USER_LIST}${id}/`;
    const formData = payload;
    try {
      await patchActiveMutateAsync({ url, formData });
    } catch (e) {
      console.log(e);
    }
  };
  const { mutateAsync: patchStaffMutateAsync } = usePatchHook({
    queryKey: ["users"],
    navigateURL: null,
  });

  const handleIsStaff = async (e: any, id: any) => {
    const payload = {
      is_staff: e.target.checked,
    };
    const url = `${APIS.USER_LIST}${id}/`;
    const formData = payload;
    try {
      await patchStaffMutateAsync({ url, formData });
    } catch (e) {
      console.log(e);
    }
  };
  const { mutateAsync: patchSuperUserMutateAsync } = usePatchHook({
    queryKey: ["users"],
    navigateURL: null,
  });

  const handleIsSuperUser = async (e: any, id: any) => {
    const payload = {
      is_superuser: e.target.checked,
    };
    const url = `${APIS.USER_LIST}${id}/`;
    const formData = payload;
    try {
      await patchSuperUserMutateAsync({ url, formData });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <UserList
        handleAddUser={handleAddUser}
        handleIsActive={handleIsActive}
        handleIsStaff={handleIsStaff}
        handleIsSuperUser={handleIsSuperUser}
      />
    </>
  );
};

export default User;
