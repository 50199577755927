import React, { useState } from "react";
import randomString from "../../../../utils/randomString";
import showicon from "../../../../assets/icons/showicon.svg";
import hideicon from "../../../../assets/icons/hideicon.svg";

const PasswordField: React.FC<any> = (props) => {
  const { errors, register, title, name, placeholder, label, helperText } =
    props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const id = randomString(20, `-${name}`);

  return (
    <div className="relative rounded">
      <input
        id={name}
        type={showPassword ? "text" : "password"}
        {...register(name)}
        placeholder={placeholder}
        className="relative peer bg-gray-200 p-2 rounded w-full text-gray-700 focus: outline-none border-b-2 border-gray-300 focus:border-purple-600 transition duration-500"
      />
      <span
        className="absolute top-[0.85rem] right-2 cursor-pointer"
        onClick={() => setShowPassword((pre: any) => !pre)}
      >
        <img
          src={showPassword ? hideicon : showicon}
          alt="eyeicon"
          className="w-4"
        />
      </span>
      {Boolean(errors[name]) && (
        <p className="text-sm text-red-500">{errors[name]?.message}</p>
      )}
      <p className="text-xs text-blue-500">{helperText}</p>
      <label
        htmlFor={id}
        className="absolute left-2 -top-2.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm"
      >
        {label}
      </label>
    </div>
  );
};

export default PasswordField;
